import Header from "./Header";
import Footer from "./Footer";
import getConfig from "next/config";
import Head from "next/head";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import React from "react";
import { getUA } from "react-device-detect";
const ZENDESK_KEY = "ab079416-4cd7-454c-8103-222a618ef066";

const { publicRuntimeConfig } = getConfig();

export default function Layout({ children }) {
  const setting = {
    color: {
      theme: "#000",
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help",
      },
    },
    contactForm: {
      fields: [{ id: "description", prefill: { "*": "My pre-filled description" } }],
    },
  };
  const isAppleMobileDevice = /iPhone|iPad|iPod/i.test(getUA);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content={`initial-scale=1.0, width=device-width${isAppleMobileDevice ? ", maximum-scale=1.0" : ""}`}
        />
        <meta name="facebook-domain-verification" content="be4zad8w45xnypeoqyej76sm3t0ia8" />
        <meta name="description" content="Trademark-Genius" />
        <link rel="icon" href={`${publicRuntimeConfig.basePath}/favicon.ico`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${publicRuntimeConfig.basePath}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${publicRuntimeConfig.basePath}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${publicRuntimeConfig.basePath}/favicon-16x16.png`} />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${publicRuntimeConfig.basePath}/android-chrome-192x192.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href={`${publicRuntimeConfig.basePath}/android-chrome-512x512.png`}
        />
      </Head>
      <Header />
      <main>{children}</main>
      <Footer />
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => ZendeskAPI("webWidget", "hide")} />
    </>
  );
}
