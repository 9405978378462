import React from 'react';
import getConfig from 'next/config';
import Link from 'next/link';
import NumberFormat from 'react-number-format';
import { ZendeskAPI } from 'react-zendesk';

const { publicRuntimeConfig } = getConfig();

const Footer = () => {
  let phone = process.env.NEXT_PUBLIC_PHONE_APP;
  const handleChat = () => {
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');

    ZendeskAPI('webWidget:on', 'close', function () {
      ZendeskAPI('webWidget', 'hide');
    });
  };
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-links">
          <div className="link-wrapper">
            <h5>Quick links</h5>
            <div className="links">
              <Link href="/trademark-registration">
                <a>Trademark Registration</a>
              </Link>
              <Link href="/comprehensive-trademark-search">
                <a>Comprehensive Search</a>
              </Link>
              <Link href="/trademark-monitoring">
                <a>Trademark Monitoring</a>
              </Link>
              <Link href="/Search3">
                <a>Free Trademark Search</a>
              </Link>
              <Link href="/trademark-registration">
                <a>Copyright Registration</a>
              </Link>
              <Link href="/office-action">
                <a>Office Action Responce</a>
              </Link>
            </div>
          </div>
          <div className="link-wrapper">
            <h5>Company</h5>
            <div className="links">
              <Link href="/about">
                <a>About Us</a>
              </Link>
              <Link href="/contact">
                <a>Contact Us</a>
              </Link>
              <Link href="/guarantee">
                <a>Our Guarantee</a>
              </Link>
              <Link href="/terms">
                <a>Terms of Service</a>
              </Link>
              <Link href="/privacy">
                <a>Privacy Policy</a>
              </Link>
              <Link href="/blog">
                <a>Blog</a>
              </Link>
            </div>
          </div>
          <div className="link-wrapper">
            <h5>Connect with us</h5>
            <div className="links">
              <Link href="/about">
                <a>About Us</a>
              </Link>
            </div>
          </div>
          <div className="link-wrapper">
            <h5>Assistance</h5>
            <div className="union" onClick={handleChat}>
              <img
                src={`${publicRuntimeConfig.basePath}/Chat Smile.png`}
                alt=""
                layout="fill"
                style={{ marginRight: '6px' }}
              />
              <img
                src={`${publicRuntimeConfig.basePath}/Live Chat.png`}
                alt=""
                layout="fill"
              />
            </div>

            <div className="phone">
              <a href={'tel:' + phone}>
                <NumberFormat
                  value={phone.substring(2)}
                  displayType={'text'}
                  format="(###)### - ####"
                />
              </a>
              <p>Mon – Fri: 9am -5 pm</p>
            </div>
          </div>
        </div>
        <div className="payments">
          <img
            src={`${publicRuntimeConfig.basePath}/paymet-icons.svg`}
            alt=""
            layout="fill"
          />
        </div>
        <p className="copyright">
          © Copyright 2021 Trademark Genius, LLC. Trademark Genius provides
          information and software only. Trademark Genius is not a "lawyer
          referral service" and does not provide legal advice or participate in
          any legal representation. Use of Trademark Genius is subject to our
          Terms of Service and Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default Footer;
